
export const totalSupply = 1000000000;
export const tokenName = "popz";
export const tokenAddress = "AT83QacyUoZtVSsV7qwe5ecpjd71o2ZAAZxQXQFnyQw1";


export const liquidityPoolKeys = {
    'id': '6SBSQxBgkmojfhH2vHAkgeCFMK6kHKmy1LHaP6DqkPHm',
    'baseMint': 'So11111111111111111111111111111111111111112',
    'quoteMint': 'AT83QacyUoZtVSsV7qwe5ecpjd71o2ZAAZxQXQFnyQw1',
    'lpMint': 'CgGmWLRFirRT3rgF1T84bvfD4jpeSRRrbUJ9q7sanSZj',
    'baseDecimals': 9,
    'quoteDecimals': 6,
    'lpDecimals': 9,
    'version': 4,
    'programId': '675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8',
    'authority': '5Q544fKrFoe6tsEbD7S8EmxGTJYAKtTVhAW5Q5pge4j1',
    'openOrders': '5NaDbve596U2EwbmPPaARWoFwZh3g4L4RgUkFm4QyFtp',
    'targetOrders': '7KHuC1QCvSGeC5jXdp6hdQZvdPTvy8zEnsugY4Ymy3cD',
    'baseVault': '5K6grmcnT2mqBJNcoo7uNR8sQcpCB5RV4nzaMQMzYVj7',
    'quoteVault': 'Fzb9Ld9RgLwuye2XLSRDkfum43aPr1vzNZeMP8BpFdas',
    'withdrawQueue': '11111111111111111111111111111111',
    'lpVault': '11111111111111111111111111111111',
    'marketVersion': 4,
    'marketProgramId': 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    'marketId': '4snw42oAYMrP8BM8HZfsVexb67na6vjrQKqjqPHk4pJk',
    'marketAuthority': '9mUcV7hesYCURViMUaA9HxJAPiFwXe52ig9G2sfmYDta',
    'marketBaseVault': 'E5gtpecZdVd7Xu2AAAytFYxgKrRbWsUqrVgjZhuo2bUs',
    'marketQuoteVault': 'F3PkqDLsR15DbFrWcQrPfUbmNEK6n73r6rbqFXdeCvpr',
    'marketBids': '33x5ycGZEgCW5k7nGijqaGyXV6LGCDa26jNmnAMa6NG2',
    'marketAsks': '9PoMrrM2PPrZVccLEZZrT3abaiuwaveCSfpkATVpH2T9',
    'marketEventQueue': 'GzY1TvWkGK4MNGpQyC9q5omG1zTsMKyhdkxFmPjXqBy7',
    'lookupTableAccount': 'J3WPh5nvxYDucBgw1n9HvJXwsJDcZwZMtyWPHbnpWXYQ'
};

export function getProjectInfo(projectName) {
    if (!projectName) {
        projectName = localStorage.getItem("project_name");
    }
    if (projectName == "popz") {
        return {
            "tokenName": "popz",
            "totalSupply": 1000000000,
            "tokenAddress": "AT83QacyUoZtVSsV7qwe5ecpjd71o2ZAAZxQXQFnyQw1",
            "liquidityPoolKeys": {
                'id': '6SBSQxBgkmojfhH2vHAkgeCFMK6kHKmy1LHaP6DqkPHm',
                'baseMint': 'So11111111111111111111111111111111111111112',
                'quoteMint': 'AT83QacyUoZtVSsV7qwe5ecpjd71o2ZAAZxQXQFnyQw1',
                'lpMint': 'CgGmWLRFirRT3rgF1T84bvfD4jpeSRRrbUJ9q7sanSZj',
                'baseDecimals': 9,
                'quoteDecimals': 6,
                'lpDecimals': 9,
                'version': 4,
                'programId': '675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8',
                'authority': '5Q544fKrFoe6tsEbD7S8EmxGTJYAKtTVhAW5Q5pge4j1',
                'openOrders': '5NaDbve596U2EwbmPPaARWoFwZh3g4L4RgUkFm4QyFtp',
                'targetOrders': '7KHuC1QCvSGeC5jXdp6hdQZvdPTvy8zEnsugY4Ymy3cD',
                'baseVault': '5K6grmcnT2mqBJNcoo7uNR8sQcpCB5RV4nzaMQMzYVj7',
                'quoteVault': 'Fzb9Ld9RgLwuye2XLSRDkfum43aPr1vzNZeMP8BpFdas',
                'withdrawQueue': '11111111111111111111111111111111',
                'lpVault': '11111111111111111111111111111111',
                'marketVersion': 4,
                'marketProgramId': 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
                'marketId': '4snw42oAYMrP8BM8HZfsVexb67na6vjrQKqjqPHk4pJk',
                'marketAuthority': '9mUcV7hesYCURViMUaA9HxJAPiFwXe52ig9G2sfmYDta',
                'marketBaseVault': 'E5gtpecZdVd7Xu2AAAytFYxgKrRbWsUqrVgjZhuo2bUs',
                'marketQuoteVault': 'F3PkqDLsR15DbFrWcQrPfUbmNEK6n73r6rbqFXdeCvpr',
                'marketBids': '33x5ycGZEgCW5k7nGijqaGyXV6LGCDa26jNmnAMa6NG2',
                'marketAsks': '9PoMrrM2PPrZVccLEZZrT3abaiuwaveCSfpkATVpH2T9',
                'marketEventQueue': 'GzY1TvWkGK4MNGpQyC9q5omG1zTsMKyhdkxFmPjXqBy7',
                'lookupTableAccount': 'J3WPh5nvxYDucBgw1n9HvJXwsJDcZwZMtyWPHbnpWXYQ'
            }
        }
    } else if (projectName == "core") {
        return {
            "tokenName": "core",
            "totalSupply": 1000000000,
            "tokenAddress": "CAdJNZ27aC59jn47CtPGmCJRYM2jaRnSGBcGQe8Epump",
            "liquidityPoolKeys": {
                "id": "9Tb2ohu5P16BpBarqd3N27WnkF51Ukfs8Z1GzzLDxVZW",
                "baseMint": "So11111111111111111111111111111111111111112",
                "quoteMint": "8u1yFh5TJhURi6pgyrJS8eyuqoWXPdKra44xt4a7qSx5",
                "lpMint": "8FsRCL1oJxzgFUyan5PdjiXpS1b39u6ZJDcFyUfnmJVM",
                "baseDecimals": 9,
                "quoteDecimals": 6,
                "lpDecimals": 9,
                "version": 4,
                "programId": "675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8",
                "authority": "5Q544fKrFoe6tsEbD7S8EmxGTJYAKtTVhAW5Q5pge4j1",
                "openOrders": "2fF8iSBfaZU4ayANYD3L48gn8XBECN1jf1Es1fsHhRrB",
                "targetOrders": "4FLxu5j4tkuF5eZHKUpfrt7zYaEDLhQKCpcX3U6mz4XK",
                "baseVault": "HQD2eNuCRbDCFfaPjFt6ZttM8EiD4BPg1MXy2ALVwobg",
                "quoteVault": "FhMHm2TVY9ULmZJvjjRx849Jn2ZRirVhJbTncHULTSvH",
                "withdrawQueue": "11111111111111111111111111111111",
                "lpVault": "11111111111111111111111111111111",
                "marketVersion": 4,
                "marketProgramId": "srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX",
                "marketId": "D8Y5M3n1Q12kfXavoQZrdCTnJbs6ohTxCQqZiJZiQTnQ",
                "marketAuthority": "6SEvXYAv5Fo9XKMvXWRQWpeJTSnyq1AD44CdAhBK2qVV",
                "marketBaseVault": "66YWqcKPuj5xsL8oveTeXTuttQJVsFNmYyxsN2fQwbGZ",
                "marketQuoteVault": "2vxvgcEhBKY3Z9SYKFfKVyXhbqaPHnhW9wLZ9nzUCwYw",
                "marketBids": "7Rga5ETjVJNQ2TJmhrqMKxXzoAYUL3Y67WCnbvSDmAvc",
                "marketAsks": "Dw34vDModT1ZZMcVu3TYwgfWFsN9MKhMue9BXjMtEiYU",
                "marketEventQueue": "2rYga2tr4cArrSm72zNJq2MuDC51PQbSxrbwVBSDmFjz",
                "lookupTableAccount": "3ZZSc2rYC9i4PnWyXodrpBa9CRK9idxkmY1JpvigC4xB"
            }
        }
    }

};